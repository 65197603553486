import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

import Logo from '../../assets/images/logo.png'
import MapImg from '../../assets/images/map.png'
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'

import Iyzico from "../../assets/images/iyzico-odeme.svg";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-area foot-back">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link to="/it-startup">
                                    <img src={Logo} alt="konya yazılım firması pdks özel yazılım" />
                                </Link>
                            </div>
                           
                            <img src={Iyzico} alt="piksel yazılım iyzico entegrasyon" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Şirketimiz</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/about-1">
                                        Hakkında
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services-1">
                                        Misyon & Vizyon
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">
                                        Gizlilik Politikası
                                    </Link>

                                </li>
                                <li>
                                    <Link to="/pricing">
                                        Çalışma Şekilleri
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog-1">
                                        Yeni Haberler
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Projeler</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim">
                                        Pera PDKS
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pro-steel-celik-konstruksiyon-uretim-takip-yazilimi">
                                        Pro Steel
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/bicak-izi-acilim-kutu-tasarimi">
                                        Kutu Tasarımı
                                    </Link>

                                </li>
                                <li>
                                    <Link to="/pawi-veteriner-asi-takip-yazilimi">
                                        Pawi Net
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/ozel-yazilim-firmasi">
                                      Çeşitili  Özel Yazilimlar
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Adres</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <Icon.MapPin />
                                    Bahçeşehir Mh. Çöven Sk No:15  <br /> Konya, TÜRKİYE
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <a  aria-label="mail"  href="mailto:finans@pixelyazilim.com.tr">finans@pixelyazilim.com.tr</a>
                                </li>
                                <li>
                                    <Icon.PhoneCall />
                                    Telefon: <a   aria-label="telefon"  href="tel:+905419388298">+90 (541) 938 82 98</a>
                                </li>
                            </ul>

                            <ul className="social-links">
                                <li>
                                    <a href="https://wa.me/+905419388298" aria-label="Whatsap" className="whatsap" target="_blank" rel="noreferrer"><Icon.MessageCircle /></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/pixel.yazilimtr" aria-label="facebook"  className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/pixelyazilim" aria-label="twitter"  className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/pixel.yazilim/" aria-label="instagram"  className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/pixelyazilim" aria-label="linkedin"  className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} Pixel Yazılım. All rights reserved by <a aria-label="Piksel" href="https://pixelyazilim.com.tr" target="_blank">Pixel Yazılım</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={MapImg} className="map" alt="konya yazılım firması pdks özel yazılım" />

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="özel yazılım projesi" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="çelik programları" />
            </div>
        </footer>
    )

}

export default Footer; 